<template lang="pug">
.container
  b-loading(:is-full-page='false' v-model='isLoading')
  page-title(:title='`Factura #${invoice.id} de ${invoice.assignedName}`')
  .card
    .card-content
      table.table.invoice-table
        tbody
          tr
            td Contrato
            td
              table-picker(
                :data='contracts'
                :data-async='getContracts'
                :isLoading='isLoadingContracts'
                :name='invoice.contractCode'
                v-model='invoice.contractId'
                :link-to='{ name: "contract", params: { id: invoice.contractId } }'
                :hide-link='!invoice.contractId'
                only-ids
              )
          tr
            td Total
            td {{ invoice.total | currency  }}
          tr
            td Archivo
            td
              invoice-file-link(v-if='invoice.id' :invoice='invoice')
          tr
            td Fecha
            td {{ invoice.date | datetime }}
          tr
            td Inicio del ciclo
            td {{ invoice.startDate | date }}
          tr
            td Fin del ciclo
            td {{ invoice.endDate | date }}
          tr
            td Expira
            td {{ invoice.expireDate | date }}
          tr
            td Estado
            td
              invoice-status-indicator.mr-1(:invoice='invoice' v-if='invoice.id')
      iframe(:src='fileUrl' v-if='fileUrl' type="application/pdf")
      collapse-card(title='Detalles de equipos')
        table.table
          thead
            tr
              th Nombre registrado
              th IMEI
              th Cantidad
              th.numeric Precio/{{chargeFrecuencyLabel}}
              th.numeric Subtotal
              th
          tbody
            tr(v-for='detail in invoice.invoiceDetailsDto')
              td {{ detail.deviceName }}
              td {{ detail.deviceId }}
              td {{ isDaily ? detail.days : detail.months  }} {{ chargeFrequencyTypeDescription }}
              td.numeric {{ detail.value | currency }}
              td.numeric {{ detail.value * (isDaily ? detail.days : detail.months) | currency }}
              td
                span(v-if='savingDetailId == detail.id') Espere...
                span(v-else)
                  a(@click='removeDetail(detail)')
                    icon(icon='trash' title='Eliminar')
        a(@click='addNewDevice') Agregar un nuevo equipo
      collapse-card(title='Otros conceptos')
        p.mb-5(v-if='otherDetails.length === 0') No hay otros conceptos agregados
        table.table(v-else)
          thead
            tr
              th Concepto
              th Cantidad
              th.numeric Precio
              th.numeric Subtotal
              th
          tbody
            tr(v-for='detail in otherDetails')
              td {{ detail.concept }}
              td {{ detail.quantity }}
              td.numeric {{ detail.unitCost | currency }}
              td.numeric {{ detail.unitCost * detail.quantity | currency }}
              td
                span(v-if='savingOtherDetailId == detail.id') Espere...
                span(v-else)
                  a(@click='removeOtherDetail(detail)')
                    icon(icon='trash' title='Eliminar')
        a(@click='addNewConcept') Agregar un nuevo concepto
  b-modal(v-model='formDetailModalActive' has-modal-card trap-focus aria-role='dialog' aria-modal :can-cancel='["x"]')
    modal-card(v-if='detail' title='Crear nuevo equipo' width='400px')
      app-form(@submit.prevent='submitDetail' :is-saving='isSaving')
        field(label='Equipo' field='deviceId')
          devices-picker(v-model='detail.deviceId' only-ids)
        field(label='Dias cobrados' field='days')
          input#quantity.input(placeholder='Ejm: 31' required v-model.number='detail.days' type='number')
        field(label='Meses cobrados' field='months')
          input#unitCost.input(placeholder='Ejm: 1' required v-model.number='detail.months' type='number')
        field(label='Valor/Precio' field='value')
          input#unitCost.input(placeholder='Precio por mes o día según el contrato' required v-model.number='detail.value' type='number')
  b-modal(v-model='formOtherDetailModalActive' has-modal-card trap-focus aria-role='dialog' aria-modal :can-cancel='["x"]')
    modal-card(v-if='otherDetail' title='Crear nuevo concepto' width='400px')
      app-form(@submit.prevent='submitOtherDetail' :is-saving='isSaving')
        field(label='Concepto' field='concept')
          input#concept.input(placeholder='Ejm. Servicio técnico de instalación' required v-model='otherDetail.concept')
        field(label='Cantidad' field='quantity')
          input#quantity.input(placeholder='Cantidad' required v-model.number='otherDetail.quantity' type='number')
        field(label='Valor por unidad' field='unitCost')
          input#unitCost.input(placeholder='Valor de cada unidad' required v-model.number='otherDetail.unitCost' type='number')
</template>

<script>
import InvoiceFileLink from './blocks/InvoiceFileLink'
import InvoiceStatusIndicator from './blocks/InvoiceStatusIndicator'
import invoicesService from '@/services/invoices.service'
import invoiceDetailsService from '@/services/invoice-details.service'
import invoiceOtherDetailsService from '@/services/invoice-other-details.service'
import DevicesPicker from '@/components/devices/DevicesPicker'
import TablePicker from '@/components/shared/TablePicker.vue'
import { chargeFrequencyTypeEnum, chargeFrequencyTypeDescriptions } from '@/constants/enums'
import toastService from '@/services/toast.service'
import contractsService from '@/services/contracts.service'

export default {
  components: { InvoiceFileLink, InvoiceStatusIndicator, DevicesPicker, TablePicker },
  created () {
    if (this.$route.params.id) {
      this.getInvoice()
    } else {
      this.getContracts()
    }
  },
  data () {
    return {
      invoice: {},
      contracts: [],
      isLoading: false,
      isLoadingContracts: false,
      fileUrl: null,
      formDetailModalActive: false,
      formOtherDetailModalActive: false,
      detail: null,
      otherDetail: null,
      isSaving: false,
      savingDetailId: null,
      savingOtherDetailId: null
    }
  },
  computed: {
    contract () {
      return (this.invoice && this.invoice.contract) || {}
    },
    chargeFrecuencyLabel () {
      switch (this.invoice.chargeFrequency) {
        case chargeFrequencyTypeEnum.Daily: return 'día'
        case chargeFrequencyTypeEnum.Montly: return 'mes'
        default: return 'día?'
      }
    },
    chargeFrequencyTypeDescription () {
      return chargeFrequencyTypeDescriptions[this.invoice.chargeFrequency]
    },
    isDaily () {
      return this.invoice.chargeFrequency === chargeFrequencyTypeEnum.Daily
    },
    otherDetails () {
      return (this.invoice && this.invoice.invoiceOtherDetails) || []
    }
  },
  methods: {
    async getInvoice () {
      this.isLoading = true
      const id = Number(this.$route.params.id)
      const result = await invoicesService.getOne(id)
      if (result) {
        this.invoice = result
      }
      this.isLoading = false
    },
    async getContracts () {
      this.isLoadingContracts = true
      const result = await contractsService.getAll()
      if (result) {
        this.contracts = result
      }
      this.isLoadingContracts = false
      return this.contracts
    },
    addNewDevice () {
      if (this.invoice.invoiceDetailsDto.length) {
        const { days, months, value } = this.invoice.invoiceDetailsDto[0]
        this.detail = { days, months, value }
      } else {
        this.detail = {}
      }
      this.formDetailModalActive = true
    },
    addNewConcept () {
      this.otherDetail = {}
      this.formOtherDetailModalActive = true
    },
    async submitDetail () {
      const invoiceId = this.invoice.id
      const detail = { invoiceId, ...this.detail }
      const existentDevice = this.invoice.invoiceDetailsDto.find(detail => detail.deviceId === this.detail.deviceId)
      if (existentDevice) {
        if (!await toastService.confirmWarning('Este dispositivo  ya está en la lista, ¿seguro que desea agregarlo?')) return
      }
      this.isSaving = true
      const result = await invoiceDetailsService.save(detail)
      if (result) {
        detail.id = result.id
        detail.deviceName = result.deviceName
        this.invoice.invoiceDetailsDto.push(detail)
        this.formDetailModalActive = false
        this.detail = null
        this.invoice.file = null
      }
      this.isSaving = false
    },
    async submitOtherDetail () {
      const invoiceId = this.invoice.id
      const detail = { invoiceId, ...this.otherDetail }
      this.isSaving = true
      const result = await invoiceOtherDetailsService.save(detail)
      if (result) {
        detail.id = result.id
        this.invoice.invoiceOtherDetails.push(detail)
        this.formOtherDetailModalActive = false
        this.otherDetail = null
        this.invoice.file = null
      }
      this.isSaving = false
    },
    async removeDetail (detail) {
      if (!await toastService.confirmWarning('¿Desea eliminar este elemento? no podrá recuperarlo después')) return
      this.savingDetailId = detail.id
      const result = await invoiceDetailsService.delete(detail)
      if (result) {
        this.invoice.invoiceDetailsDto = this.invoice.invoiceDetailsDto.filter(det => det.id !== detail.id)
      }
      this.savingDetailId = null
    },
    async removeOtherDetail (detail) {
      if (!await toastService.confirmWarning('¿Desea eliminar este elemento? no podrá recuperarlo después')) return
      this.savingOtherDetailId = detail.id
      const result = await invoiceOtherDetailsService.delete(detail)
      if (result) {
        this.invoice.invoiceOtherDetails = this.invoice.invoiceOtherDetails.filter(det => det.id !== detail.id)
      }
      this.savingOtherDetailId = null
    }
  }
}
</script>

<style scoped>
.invoice-table {
  max-width: 500px;
  width: 100%;
}
</style>
